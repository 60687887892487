import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { adminURL } from './config'

const Register = lazy(() => import('./pages/register/register'))
const Room = lazy(() => import('./pages/room/room'))
const Admin = lazy(() => import('./pages/admin/admin'))
const P404 = lazy(() => import('./pages/page404/page404'))

function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={Register}/>
          <Route exact path="/room" component={Room}/>
          <Route exact path={adminURL} component={Admin}/>
          <Route component={P404}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
